<template>
  <button class="btn text-white">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "DotButton"
 }
</script>

<style scoped>
  button {
    height: 48px !important;
    padding: 0 32px;
  }

  button:hover {
    opacity: 0.8;
  }

  /* button:active {
    opacity: 0.6;
  } */

  button:disabled {
    opacity: 0.2;
  }
  
  button {
    font-size: 1.5rem;
  }

</style>
